import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"
import BlogSidebar from "./blog-sidebar"

import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

import "./blog-post-styles.scss"

export const BlogPostTemplate = ({
  content,
  tableOfContents,
  contentComponent,
  title,
  author,
  postDate,
  timeToRead,
  imageOverlay = "/img/blog-page-overlay.png",
  leftImage,
  rightImage,
}) => {
  const PostContent = contentComponent || Content
  return (
    <div>
      <section className="section-bg position-relative">
        <PreviewCompatibleImage
          src={imageOverlay}
          className="position-absolute bg-overlay"
          style={{ height: "100%" }}
        />
        <div className="container">
          <div className="row flex-column align-items-center">
            <div className="col-lg-10 col-xl-12">
              <h1 className="text-left text-white mb-5">{title}</h1>
            </div>
            <div className="col-lg-12">
              <div className="d-flex align-items-center">
                <img src={author.authorImage.publicURL} width="80" alt="" />
                <div className="d-flex flex-column align-items-start text-white ml-4">
                  <p className="m-0 text-center">By {author.authorName}</p>
                  <p className="m-0 text-center">
                    {postDate} - {timeToRead} min read
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="blog-main">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-8 blog-main-inner py-7">
              {leftImage ? (
                <div className="row center bg-white">
                  <div className="col-lg-7 pt-0 pl-md-5 pb-3 order-1 order-lg-0">
                    <p className="p-0 font-weight-bold">
                      There was a knock at the door.....
                    </p>
                    <ul className="list-unstyled font-size-sm">
                      <li className="font-size-md mb-2">
                        At 12pm, there was a knock at the door. There were two
                        policemen standing there…
                      </li>
                      <li className="font-size-md mb-2">
                        They said, “please may we come in, there is something we
                        need to talk to you about.”
                      </li>
                      <li className="font-size-md mb-2">
                        I knew something was really wrong.
                      </li>
                      <li className="font-size-md mb-2">
                        You see these things in the movies, but you don't expect
                        it to happen in real life.
                      </li>
                      <li className="font-size-md mb-2">
                        They asked for my name, and if I have a son. I told them
                        I did.
                      </li>
                      <li className="font-size-md mb-2">
                        They then told me my son, Blake had died. Blake was just
                        31 years old.
                      </li>
                      <li>The next couple of days were pretty barbaric.</li>
                    </ul>
                  </div>
                  <div className="col-lg-5 p-0">
                    <PreviewCompatibleImage
                      src={rightImage.publicURL}
                      alt="img"
                    />
                    <p
                      className="p-2 font-weight-bold"
                      style={{ fontSize: "2rem" }}
                    >
                      Debbie
                    </p>
                  </div>
                </div>
              ) : null}

              {rightImage ? (
                <div className="row center bg-white m-0 my-8">
                  <div className="col-lg-7 pl-md-5 pb-3 pd-lg-0 order-1">
                    <p className="p-0 font-weight-bold">
                      A Call from the Coroner
                    </p>
                    <ul className="list-unstyled font-size-sm">
                      <li className="font-size-md mb-2">
                        This is the very first time that an immediate member of
                        my family passed away.
                      </li>
                      <li className="font-size-md mb-2">
                        The whole thing was totally unexpected.
                      </li>
                      <li className="font-size-md mb-2">
                        We were contacted by the coroner's office and asked if
                        we wanted to see Blake.
                      </li>
                      <li className="font-size-md mb-2">
                        When we got the call, were all still in denial.
                      </li>
                      <li className="font-size-md mb-2">
                        I was hoping they made an error in identification. I was
                        hoping this was just one big mistake.
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-5 p-0">
                    <PreviewCompatibleImage
                      src={leftImage.publicURL}
                      alt="img"
                    />
                    <p
                      className="p-2 font-weight-bold"
                      style={{ fontSize: "2rem" }}
                    >
                      Blake
                    </p>
                  </div>
                </div>
              ) : null}
              <PostContent content={content} />
            </div>
            <BlogSidebar content={tableOfContents} />
          </div>
        </div>
      </section>
    </div>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
}

const BlogPost = ({ data, path }) => {
  const { markdownRemark: post, imageOverlay, authorList } = data

  const author = authorList.edges[0].node.frontmatter.authorList.find(
    author => {
      return author.authorName === post.frontmatter.author
    }
  )
  
  return (
    <Layout loadMap={false} hasProgressBar={true}>
      <SEO
        path={path}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        image={post.frontmatter.featuredimage.publicURL}
      />
      <BlogPostTemplate
        tableOfContents={post.tableOfContents}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        title={post.frontmatter.title}
        author={author}
        leftImage={post.frontmatter.leftImage}
        rightImage={post.frontmatter.rightImage}
        postDate={post.frontmatter.date}
        timeToRead={post.timeToRead}
        imageOverlay={imageOverlay}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      timeToRead
      html
      tableOfContents(maxDepth: 2)
      frontmatter {
        date(formatString: "DD MMMM YYYY")
        title
        author
        description
        featuredimage {
          publicURL
        }
        leftImage {
          publicURL
        }
        rightImage {
          publicURL
        }
      }
    }
    imageOverlay: file(relativePath: { eq: "blog-page-overlay.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    authorList: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "author-list" } } }
    ) {
      edges {
        node {
          frontmatter {
            authorList {
              authorName
              authorImage {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`
