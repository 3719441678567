import React, { useEffect, useState, useRef } from 'react'
import PropTypes from "prop-types"

const BlogSidebar = ({ content }) => {
  const blogSidebar = useRef(null);
  const [offset, setOffset] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [originalOffset, setOriginalOffset] = useState({ top: 0, left: 0, width: 0, height: 0 });
  const [isFixed, setFixed] = useState(false);

  useEffect(() => {
    const rect = blogSidebar.current.getBoundingClientRect();

    setOriginalOffset({
      top: Math.round(rect.top),
      left: Math.round(rect.left),
      width: Math.round(rect.width),
      height: Math.round(rect.height)
    })
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const rect = blogSidebar.current.getBoundingClientRect();
      const footerDistance = document.querySelector("footer").getBoundingClientRect().top;

      if (Math.round(rect.top) <= 60 && footerDistance > 720) {
        setFixed(true);
        setOffset({
          top: 60,
          left: Math.round(rect.left),
          width: Math.round(rect.width)
        })
      } else {
        setFixed(false);
        setOffset({
          ...originalOffset
        })
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);

  }, [offset, originalOffset]);

  useEffect(() => {
    let links = null;
    let headings = [];
    let previousSection;
    const observerOptions = {
      rootMargin: "0px"
    };

    links = [...blogSidebar.current.querySelectorAll('a')];

    const changeActiveLink = (e) => {
      links.forEach(link => link.parentElement.classList.remove("isActive"));
      e.parentElement.classList.add("isActive");
    }

    links.forEach(link => {
      const linkHash = new URL(link).hash;
      const headerElem = document.querySelector(linkHash);

      link.addEventListener("click", function (e) {
        e.preventDefault();
        changeActiveLink(this);
        headerElem.scrollIntoView();
      });

      headings.push(headerElem);
    });

    const handleObserver = (entries, observer) => {

      entries.forEach(entry => {
        let href = `#${entry.target.getAttribute('id')}`,
          link = links.find(l => {
            const hash = new URL(l).hash;
            return hash === href;
          });

        // I needed to use > 1 on Codepen, not sure why?
        if (entry.isIntersecting) {
          link.parentElement.classList.add("isActive");
          previousSection = entry.target;
        } else {
          link.parentElement.classList.remove("isActive");
        }

        highlightFirstActive(); // in a minute I said
      });


    }

    const observer = new IntersectionObserver(handleObserver, observerOptions);

    const highlightFirstActive = () => {
      const sidebar = blogSidebar.current;
      let firstVisibleLink = sidebar.querySelector(".isActive");

      links.forEach(link => {
        link.parentElement.classList.remove("isActive");
      })

      if (firstVisibleLink) {
        firstVisibleLink.classList.add("isActive");
      }

      if (!firstVisibleLink && previousSection) {
        previousSection.parentElement.classList.add("isActive");
      }
    }

    headings.forEach(heading => {
      observer.observe(heading);
    });

    return () => {
      headings.forEach((heading) => {
        observer.unobserve(heading);
      });
    };
  }, []);

  return (
    <div ref={blogSidebar} className="d-none d-xl-block col-xl-4">
      <div className="p-5 blog-sidebar" style={isFixed ? { ...offset, position: "fixed", top: "60px", marginTop: "0px" } : {}}>
        <h3 className="mb-4">Contents</h3>
        <div dangerouslySetInnerHTML={{ __html: content }}></div>
      </div>
    </div>
  )
}

BlogSidebar.propTypes = {
  content: PropTypes.string.isRequired
}

export default BlogSidebar